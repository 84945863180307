import { useCallback, useEffect, useState } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import web3ModalSetup from "./../helpers/web3ModalSetup";
import Web3 from "web3";
import getAbi from "../Abi";
import logo from "./../assets/logo.svg";
import music from "./../assets/bg_music.mp3";

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
      audio.loop = true;
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const web3Modal = web3ModalSetup();

const Interface = () => {
    const [Abi, setAbi] = useState();
    const [web3, setWeb3] = useState();
    const [isConnected, setIsConnected] = useState(false);
    const [injectedProvider, setInjectedProvider] = useState();
    const [refetch, setRefetch] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [accounts, setAccounts] = useState(null);
    const [current, setCurrent] = useState(null);
    const [connButtonText, setConnButtonText] = useState("CONNECT");
    const [refLink, setRefLink] = useState(
        "https://betamine.site/?ref=0x0000000000000000000000000000000000000000"
    );
    const [contractBalance, setContractBalance] = useState(0);
    const [userBalance, setUserBalance] = useState(0);
    const [userInvestment, setUserInvestment] = useState(0);
    const [userDailyRoi, setUserDailyRoi] = useState(0);
    const [dailyReward, setDailyReward] = useState(0);
    const [startTime, setClaimStartTime] = useState(0);
    const [deadline, setClaimDeadline] = useState(0);
    const [approvedWithdraw, setApprovedWithdraw] = useState(0);
    const [lastWithdraw, setLastWithdraw] = useState(0);
    const [nextWithdraw, setNextWithdraw] = useState(0);
    const [totalWithdraw, setTotalWithdraw] = useState(0);
    const [referralReward, setReferralReward] = useState(0);
    const [refTotalWithdraw, setRefTotalWithdraw] = useState(0);
    const [jackpotData, setJackpotData] = useState([]);
    const [value, setValue] = useState('');
    const [balance, setBalance] = useState(0);

    const [pendingMessage, setPendingMessage] = useState('');
    const [calculate, setCalculator] = useState('');

    const [playing, toggle] = useAudio(music);
    
    const queryParams = new URLSearchParams(window.location.search);
    let DefaultLink = queryParams.get("ref");

    if (DefaultLink === null) {
      DefaultLink = "0x5dF68363A2DE49E3329FE8713384B2A684Ddc4a4";
    }

    
    // Define provider options
    const web3ModalProviderOptions = {
        walletconnect: {
            package: WalletConnectProvider, // Required
            options: {
                rpc: {
                    698: "https://rpc.matchain.io", // Replace with your RPC URL
                },
            },
        },
        // Add more providers here if needed
    };
    
    // Initialize Web3Modal with the defined options
    const web3Modal = new Web3Modal({
        cacheProvider: true, // optional
        providerOptions: web3ModalProviderOptions, // Required
    });
    
    const logoutOfWeb3Modal = async () => {
        try {
            await web3Modal.clearCachedProvider();
            if (
                injectedProvider &&
                injectedProvider.provider &&
                typeof injectedProvider.provider.disconnect === "function"
            ) {
                await injectedProvider.provider.disconnect();
            }
        } catch (error) {
            console.error('Error during logout:', error);
        } finally {
            setIsConnected(false);
            window.location.reload();
        }
    };
    
    const loadWeb3Modal = useCallback(async () => {
        try {
            const provider = await web3Modal.connect();
            const web3Instance = new Web3(provider);
    
            // Detect account and network
            const accounts = provider.selectedAddress ? [provider.selectedAddress] : provider.accounts;
            const networkId = await web3Instance.eth.getChainId();
            const desiredNetworkId = 698; // Replace with your desired network ID (e.g., 1 for Ethereum Mainnet)
    
            // Check if connected to the desired network
            if (networkId !== desiredNetworkId) {
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: `0x${desiredNetworkId.toString(16)}`,
                            chainName: 'Matchain Mainnet',
                            rpcUrls: ['https://rpc.matchain.io'],
                            nativeCurrency: {
                                name: 'Matchain',
                                symbol: 'BNB',
                                decimals: 18
                            },
                            blockExplorerUrls: ['https://matchscan.io']
                        }]
                    });
                } catch (switchError) {
                    console.error('Error switching network:', switchError);
                    alert('Please switch to the Matchain Network manually.');
                    return;
                }
            }
    
            // Update the state with the connected Web3 instance and user details
            setInjectedProvider(web3Instance);
            setWeb3(web3Instance);
            setAbi(await getAbi(web3Instance));
            setAccounts(accounts);
            setCurrent(accounts[0]);
            setIsConnected(true);
            setConnButtonText(shortenAddr(accounts[0]));
    
            // Add event listeners for mobile wallet support
            if (provider.on) {
                provider.on('chainChanged', (chainId) => {
                    console.log(`Chain changed to ${chainId}`);
                    window.location.reload(); // Reload the page to handle the new chain
                });
    
                provider.on('accountsChanged', (newAccounts) => {
                    console.log('Account changed:', newAccounts);
                    setAccounts(newAccounts);
                    setCurrent(newAccounts[0]);
                    setConnButtonText(shortenAddr(newAccounts[0]));
                });
    
                provider.on('disconnect', (code, reason) => {
                    console.log(`Disconnected: ${code} - ${reason}`);
                    logoutOfWeb3Modal();
                });
            }
    
        } catch (error) {
            console.error('Error loading Web3 modal:', error);
            alert('Connection failed. Please try again.');
        }
    }, [web3Modal]);
    
    // Detect and handle Trust Wallet or mobile browsers
    useEffect(() => {
        if (window.ethereum && window.ethereum.isTrust) {
            web3ModalProviderOptions.walletconnect = {
                package: WalletConnectProvider, // required
                options: {
                    rpc: {
                        698: "https://rpc.matchain.io", // Replace with your RPC URL
                    },
                },
            };
        }
    }, []);
    


    useEffect(() => {
        setInterval(() => {
            setRefetch(prevRefetch => !prevRefetch);
        }, 3000);
    }, []);

    useEffect(() => {
        if (web3Modal.cachedProvider) {
            loadWeb3Modal();
        }
    }, [web3Modal, loadWeb3Modal]);

    const shortenAddr = addr => {
        if (!addr) return "";
        const first = addr.substr(0, 6);
        const last = addr.substr(-4);
        return `${first}...${last}`;
    };

    useEffect(() => {
        const refData = async () => {
            if (isConnected && web3) {
                const balance = await web3.eth.getBalance(current);
                const refLink = "https://betamine.site/?ref=" + current;
                setRefLink(refLink);
                setBalance(web3.utils.fromWei(balance));
            }
        };
        refData();
    }, [isConnected, current, web3, refetch]);

    useEffect(() => {
        const AbiContract = async () => {
            if (!isConnected || !web3) return;
            const contractBalance = await Abi.methods.getBalance().call();
            setContractBalance(contractBalance / 10e17);
        };
        AbiContract();
    }, [isConnected, web3, Abi, refetch]);

    useEffect(() => {
        const Contract = async () => {
            if (isConnected && Abi) {
                let userBalance = await web3.eth.getBalance(current);
                setUserBalance(userBalance);
                let userInvestment = await Abi.methods.investments(current).call();
                setUserInvestment(userInvestment.invested / 10e17);
                let dailyRoi = await Abi.methods.DailyRoi(userInvestment.invested).call();
                setUserDailyRoi(dailyRoi / 10e17);
                let dailyReward = await Abi.methods.userReward(current).call();
                setDailyReward(dailyReward / 10e17);
            }
        };
        Contract();
    }, [refetch]);

    useEffect(() => {
        const Withdrawlconsole = async () => {
            if (isConnected && Abi) {
                let approvedWithdraw = await Abi.methods.approvedWithdrawal(current).call();
                setApprovedWithdraw(approvedWithdraw.amount / 10e17);
                let totalWithdraw = await Abi.methods.totalWithdraw(current).call();
                setTotalWithdraw(totalWithdraw.amount / 10e17);
            }
        };
        Withdrawlconsole();
    }, [refetch]);

    useEffect(() => {
        const TimeLine = async () => {
            if (isConnected && Abi) {
                let claimTime = await Abi.methods.claimTime(current).call();
                if (claimTime.startTime > 0) {
                    let _claimStart = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(claimTime.startTime + "000");
                    let _claimEnd = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(claimTime.deadline + "000");
                    setClaimStartTime(_claimStart);
                    setClaimDeadline(_claimEnd);

                    let weekly = await Abi.methods.weekly(current).call();
                    let _start = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(weekly.startTime + "000");
                    let _end = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(weekly.deadline + "000");

                    setLastWithdraw(_start);
                    setNextWithdraw(_end);
                }
            }
        }
        TimeLine();
    }, [refetch]);

    useEffect(() => {
        const ContractReward = async () => {
            if (isConnected && Abi) {
                let refEarnedWithdraw = await Abi.methods.referral(current).call();
                setReferralReward(refEarnedWithdraw.reward / 10e17);
                let refTotalWithdraw = await Abi.methods.refTotalWithdraw(current).call();
                setRefTotalWithdraw(refTotalWithdraw.totalWithdraw / 10e17);
            }
        };
        ContractReward();
    }, [refetch]);

    useEffect(() => {
        const Jackpot = async () => {
            if (isConnected && Abi) {
                let jackpotData = await Abi.methods.jackpot().call();
                const winnerTime = Number(jackpotData[0]);
                const lastAddr = shortenAddr(jackpotData[1]);
                const lastAmount = jackpotData[2] / 10e17;
                const addr = shortenAddr(jackpotData[3]);
                const amount = jackpotData[4] / 10e17;
                const newData = [winnerTime, lastAddr, lastAmount, addr, amount];
                setJackpotData(newData);
            } else {
                const newData = ["0", "0x0...000", "0", "0x0...000", "0"];
                setJackpotData(newData);
            }
        };
        Jackpot();
    }, [refetch]);

    // Buttons

    const ClaimNow = async (e) => {
        e.preventDefault();
        if (isConnected && Abi) {
            setPendingMessage("Claiming Funds");
            await Abi.methods.claimDailyRewards().send({
                from: current,
            });
            setPendingMessage("Claimed Successfully");
        } else {
            console.log("connect wallet");
        }
    };

    const withDraw = async (e) => {
        e.preventDefault();
        if (isConnected && Abi) {
            setPendingMessage("Withdrawing funds");
            await Abi.methods.withdrawal().send({
                from: current,
            });
            setPendingMessage("Successfully Withdrawn");
        } else {
            console.log("connect wallet");
        }
    };

    const refWithdraw = async (e) => {
        e.preventDefault();
        if (isConnected && Abi) {
            setPendingMessage("Rewards withdrawing");
            await Abi.methods.Ref_Withdraw().send({
                from: current,
            });
            setPendingMessage("Successfully Withdrawn");
        } else {
            console.log("connect wallet");
        }
    };

    const closeBar = async (e) => {
        e.preventDefault();
        setPendingMessage('');
    };

    const deposit = async (e) => {
        e.preventDefault();
        if (isConnected && Abi) {
            setPendingMessage("Deposit Pending...!");
            let _value = web3.utils.toWei(value);
            await Abi.methods.deposit(DefaultLink).send({
                from: current,
                value: _value
            });
            setPendingMessage("Successfully Deposited");
        } else {
            console.log("connect wallet");
        }
    };

    const unStake = async (e) => {
        e.preventDefault();
        if (isConnected && Abi) {
            setPendingMessage("Unstaking");
            await Abi.methods.unStake().send({
                from: current,
            });
            setPendingMessage("Unstaked Successfully");
        } else {
            console.log("connect wallet");
        }
    };

    // Countdown

    const [countdown, setCountdown] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    const getCountdown = deadline => {
        const now = Date.now() / 1000;
        let total = deadline - now;
        if (total < 0) {
            total = 0;
        }
        const seconds = Math.floor(total % 60);
        const minutes = Math.floor((total / 60) % 60);
        const hours = Math.floor((total / (60 * 60)) % 24);

        return {
            hours,
            minutes,
            seconds
        };
    };

    useEffect(() => {
        const interval = setInterval(() => {
            try {
                const tillTime = 24 * 3600 + Number(jackpotData[0]);
                const data = getCountdown(tillTime);
                setCountdown({
                    hours: data.hours,
                    minutes: data.minutes,
                    seconds: data.seconds
                });
            } catch (err) {
                console.log(err);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [jackpotData[0]]);


// RENDER

     return( 
         <>

<nav className="navbar navbar-expand-sm navbar-dark" style={{marginTop: "50px", marginBottom: "30px"}}>
{/* <div className="container"> */}
  <div className="container container-fluid">
    
    <a className="navbar-brand" href="https://betamine.site"><img src={logo} alt="logo" className="img-fluid" style={{width:"586px"}} /></a>
    
    <ul className="navbar-nav me-auto">
    </ul>

    <button className="btn-sound" onClick={toggle}>
      {playing ? <i className="fas fa-volume-mute"></i> : <i className="fas fa-volume-up"></i>}
      {playing ? " OFF" : " ON "}
    </button>

    <button className="btn btn-primary btn-lg btnd btn-custom" onClick={loadWeb3Modal}><i className="fas fa-wallet"></i> {connButtonText}</button>
  </div>
</nav>
<br />

          <div className="container">
             
        {pendingMessage!==''? 
         <>
           <center><div className="alert alert-warning alert-dismissible">
         <p onClick={closeBar} className="badge bg-dark" style={{float:"right",cursor: "pointer"}}>X</p>
               {pendingMessage}</div></center>
          </> :

          <></>
         }
        
              <div className="row">
                 <div className="col-sm-3">
                   <div className="card">
                     <div className="card-body">
                   <center>  <h3 className="subtitle">LIVE ON MATCHAIN 🔥</h3>
                     
                     <h3 className="content-text13"> Stake Matchain🔥 BNB and Earn a consistent 10% return on your Stake daily upto 5x, plus a chance to win daily Jackpot Prize💰. Watch your assets grow effortlessly with our automated reward system.</h3>
                     
                     </center>
                     </div>
                   </div>
                 </div>

 
                 <div className="col-sm-3">
                   <div className="card">
                     <div className="card-body">
                   <center>                     <h3 className="content-text13"> Ensure you are connected to Matchain🔥 Network, For more info read Docs or contact us on X 'Twitter'</h3>
                   <a href="https://x.com/EVMbetamine" target="_blank" rel="noopener noreferrer" className="subtitle-link">
  <h3 className="subtitle">READ DOCS</h3>
</a>
                                          
                     </center>

                     </div>
                   </div>
                 </div>


                 <div className="col-sm-3">
                   <div className="card">
                     <div className="card-body">
                   <center>  <h3 className="subtitle">DAILY ROI</h3>
                     
                     <h3 className="value-text">10%</h3>
                     
                     </center>
                     </div>
                   </div>
                 </div>

              </div>
            
                </div> 
 


           <br /> <div className="container">
           <div className="row">
            

             <div className="col-sm-4">
           <div className="card cardDino">
            
             <div className="card-body">
             <h4 className="subtitle-normal"><b>INVESTMENT PORTAL</b></h4>
                <hr />
             <table className="table">
               <tbody>
               <tr>
                 <td><h5 className="content-text"><b>WALLET BALANCE</b></h5></td>
                 <td style={{textAlign:"right"}}><h5 className="value-text">{Number(userBalance / 10e17).toFixed(6)} BNB</h5></td>
               </tr>

               <tr>
                 <td><h5 className="content-text"><b>USER INVESTED</b></h5></td>
                 <td style={{textAlign:"right"}}><h5 className="value-text">{Number(userInvestment).toFixed(6)} BNB</h5></td>
               </tr>

               <tr>
                 <td><h5 className="content-text"><b>5x PROFIT</b></h5></td>
                 <td style={{textAlign:"right"}}><h5 className="value-text">{Number(userInvestment * 5).toFixed(6)} BNB</h5></td>
               </tr>

               <tr>
                 <td><h5 className="content-text"><b>5x REMAINING</b></h5></td>
                 <td style={{textAlign:"right"}}><h5 className="value-text">{Number(userInvestment * 5 - totalWithdraw).toFixed(6)} BNB</h5></td>
               </tr>

               <tr>
                 <td><h5 className="content-text"><b>DAILY USER ROI</b></h5></td>
                 <td style={{textAlign:"right"}}><h5 className="value-text">{Number(userDailyRoi).toFixed(10)} BNB</h5></td>
               </tr>
               </tbody>
             </table>
           
             <form onSubmit={deposit}>
                 <table className="table">
                   <tbody>
                 <tr><td>  <input
                        type="number"
                        placeholder="INPUT AMOUNT"
                        className="form-control input-box"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                 </td>


                
                <td style={{textAlign:"right"}}>
                            <button className="btn btn-primary btn-lg btn-custom">DEPOSIT</button>
                </td>

             </tr>
             </tbody>
            </table>
                      
             </form>
             
            
        
          <center>
      <button className="btn btn-primary btn-lg btn-custom" style={{marginTop:"-10px"}} onClick={unStake}>UNSTAKE</button>
     
</center>
             </div>
             </div>
     
           </div>
           

             <div className="col-sm-4">
             <div className="card cardDino">
               <div className="card-body">
               <h4 className="subtitle-normal"><b>STATISTICS</b></h4>
                <hr />
               <table className="table">
                 <tbody>
               <tr>
                 <td><h6 className="content-text14" style={{lineHeight: "20px"}}><b>DAILY REWARDS</b> <br /> <span className="value-text">{Number(dailyReward).toFixed(6)}/{userDailyRoi} BNB</span></h6></td>

                 <td style={{textAlign:"right"}}><button className="btn btn-primary btn-lg btn-custom" onClick={ClaimNow}>CLAIM</button></td>
               </tr>
               <tr>
                 <td><h6 className="content-text14" style={{lineHeight: "30px"}}><b>LAST CLAIM</b><br /><span className="value-text-12">{startTime}</span></h6></td>
                 
                 <td style={{textAlign:"right"}}><h6 className="content-text14" style={{lineHeight: "30px"}}><b>NEXT CLAIM</b><br /><span className="value-text-12">{deadline}</span></h6></td>
               </tr>

               <tr>
                 <td><h6 className="content-text14" style={{lineHeight: "20px"}}><b>50% AVAILABLE WITHDRAWAL</b> <br /><span className="value-text">{Number(approvedWithdraw).toFixed(6)} BNB</span></h6></td>
                 <td style={{textAlign:"right"}}><button className="btn btn-primary btn-lg btn-custom"  onClick={withDraw}>WITHDRAW</button></td>
               </tr>

               <tr>
                 <td><h6 className="content-text14" style={{lineHeight: "30px"}}><b>LAST WITHDRAWAL</b><br /><span className="value-text-12">{lastWithdraw}</span></h6></td>
                 
                 <td style={{textAlign:"right"}} ><h6 className="content-text14" style={{lineHeight: "30px"}}><b>NEXT WITHDRAWAL</b><br /><span className="value-text-12">{nextWithdraw}</span></h6></td>
               </tr>

           
               <tr>
                 <td><h5 className="content-text">TOTAL WITHDRAWN</h5></td>
                 <td style={{textAlign:"right"}}><h5 className="value-text">{Number(totalWithdraw).toFixed(6)} BNB</h5></td>
               </tr>

            </tbody>
             </table>
               </div>
             </div>
            </div>

            <div className="col-sm-4">
              <div className="card">
                <div className="card-body">
                <h4 className="subtitle-normal"><b>REFERRAL REWARDS  10%</b></h4>
                <hr />
                  <table className="table">
                    <tbody>
                    <tr>
                      <td><h5 className="content-text">BNB REWARDS</h5></td>
                      <td style={{textAlign:"right"}}><h5 className="value-text">{Number(referralReward).toFixed(6)} BNB</h5></td>
                    </tr>
                    <tr>
                      <td><h5 className="content-text">TOTAL WITHDRAWN</h5></td>
                      <td style={{textAlign:"right"}}><h5 className="value-text">{Number(refTotalWithdraw).toFixed(6)} BNB</h5></td>
                    </tr>
                    </tbody>
                  </table>
                 <center> <button className="btn btn-primary btn-lg btn-custom" onClick={refWithdraw}>WITHDRAW REWARDS</button> </center>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body">
                <h4 className="subtitle-normal"><b>REFERRAL LINK</b></h4>
              <hr />
               <form>
                <span className="content-text13">Share your referral link to earn 10% of BNB </span>
                <br />
                <br />
                 <input type="text" value={refLink} className="form-control input-box" readOnly />
            
               </form>
                 </div>
              </div>
            </div>
        
           </div>
        <br />
           <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-header" style={{border: "none"}}>
                    <h3 className="subtitle-normal">20% DAILY JACKPOT</h3>
                    <h5 className="value-text-12" style={{lineHeight: "20px"}}>{`${countdown.hours}H ${countdown.minutes}M ${countdown.seconds}S`}</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">

                      <h3 className="subtitle-normal" style={{fontSize: "16px", lineHeight: "20px"}}>CURRENT WINNER</h3>

                        <table className="table">
                          <tbody>
                            <tr style={{border: "hidden"}}>
                              <td><h6 className="content-text14"><b>ADDRESS</b> <br /> <span className="value-text">{jackpotData[3]}</span></h6></td>
                              <td style={{textAlign:"right"}}><h6 className="content-text14"><b>DEPOSIT</b><br /><span className="value-text">{Number(jackpotData[4]).toFixed(6)} BNB</span></h6></td>
                            </tr>
                          </tbody>
                        </table>

                        {/* <div className="row">
                          <div className="col-sm-6">
                            <h3 className="content-text14">ADDRESS</h3>
                          </div>
                          <div className="col-sm-6">
                            <h3 className="content-text14">DEPOSIT</h3>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-6">
                            <h3 className="value-text">0x1C...25D</h3>
                          </div>
                          <div className="col-sm-6">
                            <h3 className="value-text">15.88 BNB</h3>
                          </div>
                        </div> */}

                      </div>
                      <div className="col-sm-6">

                        <h3 className="subtitle-normal" style={{fontSize: "16px", lineHeight: "20px"}}>PREVIOUS WINNER</h3>

                        <table className="table">
                          <tbody>
                            <tr style={{border: "hidden", paddingRight: "10px"}}>
                              <td><h6 className="content-text14"><b>ADDRESS</b> <br /> <span className="value-text">{jackpotData[1]}</span></h6></td>
                              <td style={{textAlign:"right"}}><h6 className="content-text14"><b>DEPOSIT</b><br /><span className="value-text">{Number(jackpotData[2]).toFixed(6)} BNB</span></h6></td>
                            </tr>
                          </tbody>
                        </table>

                        {/* <div className="row">
                          <div className="col-sm-6">
                            <h3 className="content-text14">ADDRESS</h3>
                          </div>
                          <div className="col-sm-6">
                            <h3 className="content-text14">DEPOSIT</h3>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-6">
                            <h3 className="value-text">0x1C...25D</h3>
                          </div>
                          <div className="col-sm-6">
                            <h3 className="value-text">15.88 BNB</h3>
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
             <div className="col-sm-6">
               <div className="card">
                 <div className="card-header" style={{border: "none"}}>
                  <h3 className="subtitle-normal">INVESTMENT CALCULATOR</h3>
                 </div>
                
                 <div className="card-body" style={{paddingTop: "0.6rem"}}>
                  
                 <div className="row">
                   <div className="col-sm-6">
                   {/* <h3 className="subtitle-normal" style={{fontSize: "16px"}}>BNB AMOUNT</h3> */}
                   <input
                     type="number"
                     placeholder="INPUT AMOUNT"
                     className="form-control input-box"
                     value={calculate}
                     onChange={(e) => setCalculator(e.target.value)}
                   />
                   <br />
                   <p className="content-text13">Amount of returns calculated on the basis of Staked amount. 
<br />
<b></b></p>
                   </div>
                   <div className="col-sm-6" style={{textAlign:"right"}}>
                     <h3 className="subtitle-normal" style={{fontSize: "16px"}}>ROI</h3>
                  <p className="content-text13">DAILY RETURN: <span className="value-text">{Number(calculate / 100 * 10).toFixed(3)} BNB</span> <br /> WEEKLY RETURN: <span className="value-text">{Number(calculate / 100 * 10 * 7).toFixed(3)} BNB</span>  <br /> MONTHLY RETURN: <span className="value-text">{Number(calculate / 100 * 10 * 30).toFixed(3)} BNB</span> </p> 
                     </div>
                 </div>
                 </div>
               </div>
             </div>
           </div>
  <br />

           <br />
           <center>
            <h5 className="footer-item-text">
              <a href="https://betamine.gitbook.io/betamine-bnb-staking-on-matchain" target="_blank" rel="noreferrer" style={{color:"#ffffff",textDecoration:"none"}}> DOCS </a>&nbsp;&nbsp;&nbsp;
              <a href="https://x.com/EVMbetamine" target="_blank" rel="noreferrer" style={{color:"#ffffff",textDecoration:"none"}}> TWITTER </a>&nbsp;&nbsp;&nbsp;
            </h5>
            <br />
            <p style={{color: "#ffffff",fontSize: "14px",fontWeight: "200"}}>COPYRIGHT © 2024 BETAMINE.SITE ALL RIGHTS RESERVED</p>
           </center>
           <br />
    </div>
          </> 
         
     );
}

export default Interface;

import 'bootstrap/dist/css/bootstrap.min.css';
// import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import Interface from './components';

const App = () => {
return (
<>
<Interface />
</>

);
}

export default App;